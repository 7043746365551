<template>
  <subscribe-form v-model:emailAddress="emailAddress" v-model:metadata="metadata" :newsletter="newsletter"
    :subscriber="subscriber" :confirm-email-address="confirmEmailAddress" :check-subscriber="checkSubscriber"
    :subscribe="subscribe" :resubscribe="resubscribe" :state="state" :variant="variant" :error="error" />
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, PropType } from "vue";

import { components as OpenAPI } from "@/autogen/openapi";
import Urls from "@/autogen/urls";
import { Newsletter } from "@/types/newsletter";
import { debounce } from "@/utils";

import {
  BasicSubscriber,
  subscribe,
  SubscribeFormVariant,
  SubscriptionState,
} from "./lib";
import SubscribeForm from "./SubscribeForm.vue";

const calculateSubscriptionState = (
  subscriber: BasicSubscriber | undefined
): SubscriptionState => {
  if (subscriber === null || subscriber === undefined) {
    return "not_subscribed";
  }
  return subscriber.type;
};

declare var REFERRING_SUBSCRIBER_ID: string;

export default defineComponent({
  components: {
    SubscribeForm,
  },

  props: {
    newsletter: {
      type: Object as PropType<Newsletter>,
      required: true,
    },
    subscriberEmail: {
      type: String as PropType<string | undefined>,
    },
    variant: {
      type: String as PropType<SubscribeFormVariant>,
      required: true,
    },
  },
  data() {
    return {
      state: <SubscriptionState>"not_subscribed",
      emailAddress: this.subscriberEmail || "",
      metadata: {} as { [key: string]: string },
      subscriber: <BasicSubscriber | undefined>undefined,
      error: "",
    };
  },

  watch: {
    emailAddress: debounce(async function (this: any) {
      this.checkSubscriber();
    }, 500),
    newsletter: {
      handler() {
        this.state = calculateSubscriptionState(this.subscriber);
      },
      immediate: true,
    },
    subscriber: {
      handler() {
        this.state = calculateSubscriptionState(this.subscriber);
        if (this.subscriber) {
          this.emailAddress = this.subscriber.email_address;
        }
      },
      immediate: true,
    },
  },

  methods: {
    async subscribe() {
      this.state = "pending_subscription";
      const result = await subscribe(
        this.emailAddress,
        this.metadata,
        this.newsletter,
        REFERRING_SUBSCRIBER_ID
      );
      if (!("error" in result)) {
        this.subscriber = result.subscriber;
        this.state = calculateSubscriptionState(result.subscriber);
        if (this.newsletter.subscription_redirect_url) {
          window.location.href = this.newsletter.subscription_redirect_url;
        }
      } else {
        this.error = result.error;
        // (We should probably unify this logic with `checkSubscriber` at some point.)
        // If we can't subscribe _because there's already a subscription_, we should
        // simply log in that subscriber and call it a day.
        if (this.error === "Email already exists" && result.subscriber) {
          this.subscriber = result.subscriber;
          this.state = calculateSubscriptionState(result.subscriber);
        } else if (this.error === "Email already exists") {
          this.state = "errored_email";
          this.error = "This email address is already subscribed.";
        } else {
          this.state = "invalid_subscription";
        }
      }
    },
    async resubscribe(subscriber_id: string) {
      this.state = "pending_subscription";

      // For a while, we had a comment saying:
      // > This implementation, I think, is technically insufficient; if a subscriber
      // > has a paused/churned subscription it should be set to that instead of the mere 'unpaid'.
      // > But that's a relatively complicated bit of logic to ship to the frontend, so I think
      // > this is sufficient.
      // It's still too much to submit to the frontend so we're just going to override
      // the type on the backend if such a case is detected. (This is not part of the public API,
      // so I don't worry that bad about abstraction leak or the general jank level here.)
      const type = this.newsletter.should_disable_non_premium_subscriptions
        ? "unpaid"
        : "regular";
      const response = await fetch(Urls["update-subscriber"](subscriber_id), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ type }),
      });
      const subscriber = (await response.json()).subscriber;

      this.subscriber = {
        ...(this.subscriber as OpenAPI["schemas"]["Subscriber"]),
        id: subscriber.id as string,
        type: subscriber.type as OpenAPI["schemas"]["Subscriber"]["type"],
      };
    },
    async checkSubscriber() {
      if (this.emailAddress.length === 0) {
        return;
      }
      if (!this.emailAddress.includes("@")) {
        return;
      }
      this.state = "pending_validation";
      const response = await axios.post(Urls["check-subscriber-email"](), {
        email_address: this.emailAddress,
        newsletter_id: this.newsletter.id,
      });
      if (response.data.subscriber) {
        this.subscriber = response.data.subscriber;
      } else {
        if (response.data.is_valid === undefined) {
          this.state = "errored_email";
          this.error = response.data.error;
        } else if (response.data.is_valid) {
          this.state = "not_subscribed";
        } else if (response.data.error === "Fails regex validation") {
          this.state = "invalid_email__regex";
          this.error = response.data.error;
        } else {
          this.state = "invalid_email";
          this.error = response.data.error;
        }
      }
    },
    async confirmEmailAddress() {
      this.state = "pending_email_address_confirmation";
      await axios.post(Urls["start-email-confirmation"](), {
        email: this.emailAddress,
        newsletter_id: this.newsletter.id,
      });
      this.state = "awaiting_email_address_confirmation";
    },
  },
});
</script>
