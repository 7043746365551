/* eslint no-unused-vars: 0 */
import "vite/modulepreload-polyfill";

import * as Sentry from "@sentry/vue";
import axios from "axios";
import { createApp } from "vue";

import SubscribeFormContainer from "./components/SubscribeForm/SubscribeFormContainer.vue";

// NEWSLETTER should be defined in `subscribe_form.html`
// eslint-disable-next-line
const constructApp = () => {
  return createApp(SubscribeFormContainer, {
    newsletter: NEWSLETTER, // eslint-disable-line no-undef
    extantSubscriber: null,
    subscriberEmail: SUBSCRIBER_EMAIL, // eslint-disable-line no-undef
    variant: VARIANT, // eslint-disable-line no-undef
  });
};

// Fix CSRF.
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const primaryApp = constructApp();

// Enable Sentry.
// Via https://github.com/getsentry/sentry-javascript/issues/2925.
const shouldTrackErrors = NEWSLETTER.should_track_errors; // eslint-disable-line no-undef
if (shouldTrackErrors) {
  Sentry.init({
    app: primaryApp,
    dsn: "https://98d0ca1c1c554806b630fa9caf185b1f@o97520.ingest.sentry.io/6063581",
    integrations: [
      Sentry.browserTracingIntegration({
        tracingOrigins: ["buttondown.email", "buttondown.com"],
      }),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      "Non-Error",
      "Can't find variable: pktAnnotationHighlighter",
      "ChunkLoadError",
      // Safari is incorrectly failing to parse some JSON+LD metadata. This is a known issue in Safari:
      // https://github.com/getsentry/sentry/issues/61469
      // Here's an example of such an issue:
      // https://buttondown-email.sentry.io/issues/5420885923/
      '"telephone"',
    ],
  });
}

primaryApp.mount("#subscribe-form");

primaryApp.config.errorHandler = (err) => {
  Sentry.captureException(err);
};

const secondaryForms = document.getElementsByClassName("subscribe-form");
Array.from(secondaryForms).forEach((el) => {
  constructApp().mount(el);
});

// Handle Substack-imported forms.
const CLASS_NAMES = [
  "subscribe-widget",
  "subscription-widget-subscribe",
];
CLASS_NAMES.map(className => {
  const substackForms = document.getElementsByClassName(className);
  Array.from(substackForms).forEach((el) => {
    el.className = "subscribe-form";
    el.style.marginBottom = "1rem";
    constructApp().mount(el);
  });
});

export default primaryApp;
